import React, { useEffect, useState } from 'react'
import { useKeenSlider } from 'keen-slider/react'
import 'keen-slider/keen-slider.min.css'
import './ProjectDetail.css'

function ProjectDetail({ project, onClose }) {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        onClose()
      }
    }

    // Add the event listener when component mounts
    window.addEventListener('keydown', handleKeyDown)

    // Clean up the event listener when component unmounts
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [onClose]) // Recreate the event listener when onClose changes

  const [currentSlide, setCurrentSlide] = useState(0)
  const [loaded, setLoaded] = useState(false)
  const [sliderRef, instanceRef] = useKeenSlider({
    initial: 0,
    slides: {
      perView: 1,
    },
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel)
    },
    created() {
      setLoaded(true)
    },
  })

  if (!project) {
    return <div>Loading...</div> // Or return null, or some loading spinner
  }

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div id='background-cover'>
      <div id='project-detail'>
        <div id='title-bar'>
          <h3>{project.name}</h3>
          <button
            id='close-button'
            className='btn btn--outline'
            type='button'
            onClick={onClose}
          >
            Close
          </button>
        </div>

        {project.images && project.images.length > 0 && (
          <>
            <div className='navigation-wrapper'>
              <div ref={sliderRef} className='keen-slider'>
                {project.images.map((image) => (
                  <div className='keen-slider__slide' key={image.image}>
                    <img
                      className='slide-image'
                      onLoad={(e) => {
                        e.target.style.visibility = 'visible'
                      }}
                      style={{ visibility: 'hidden' }}
                      src={`${process.env.PUBLIC_URL}/${image.image}`}
                      alt={image.alt}
                    />
                  </div>
                ))}
              </div>
              {loaded && instanceRef.current && (
                <>
                  <Arrow
                    left
                    onClick={(e) =>
                      e.stopPropagation() || instanceRef.current?.prev()
                    }
                    disabled={currentSlide === 0}
                  />

                  <Arrow
                    onClick={(e) =>
                      e.stopPropagation() || instanceRef.current?.next()
                    }
                    disabled={
                      currentSlide ===
                      instanceRef.current.track.details.slides.length - 1
                    }
                  />
                </>
              )}
            </div>
            {loaded && instanceRef.current && (
              <div className='dots'>
                {[
                  ...Array(
                    instanceRef.current.track.details.slides.length,
                  ).keys(),
                ].map((idx) => (
                  // eslint-disable-next-line jsx-a11y/control-has-associated-label, react/button-has-type
                  <button
                    key={idx}
                    onClick={() => {
                      instanceRef.current?.moveToIdx(idx)
                    }}
                    className={`dot${currentSlide === idx ? ' active' : ''}`}
                  />
                ))}
              </div>
            )}
          </>
        )}

        <p className='project-details-description'>
          {project.long_description}
        </p>
        {/* More details here */}
      </div>
    </div>
  )
}

function Arrow({ disabled, onClick, left }) {
  const disabledArrow = disabled ? ' arrow--disabled' : ''
  return (
    <svg
      onClick={onClick}
      className={`arrow ${
        left ? 'arrow--left' : 'arrow--right'
      } ${disabledArrow}`}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 24 24'
    >
      {left && (
        <path d='M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z' />
      )}
      {!left && <path d='M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z' />}
    </svg>
  )
}

export default ProjectDetail
