import { useState, use, useEffect } from 'react'
import uniqid from 'uniqid'
import { projects } from '../../portfolio'
import ProjectContainer from '../ProjectContainer/ProjectContainer'
import './Projects.css'

function Projects() {
  if (!projects.length) return null

  const [isLoading, setIsLoading] = useState(true)

  const cacheImages = async (srcArray) => {
    const promises = await srcArray.map(
      (src) =>
        new Promise((resolve, reject) => {
          const img = new Image()

          img.src = src
          img.onload = resolve()
          img.onerror = reject()
        }),
    )
    await Promise.all(promises)
    setIsLoading(false)
  }

  useEffect(() => {
    cacheImages(
      projects.map((project) =>
        project.images.map(
          (image) => `${process.env.PUBLIC_URL}/${image.image}`,
        ),
      ),
    )
  }, [])

  return (
    <section id='projects' className='section projects'>
      <h2 className='section__title'>Projects</h2>

      <div className='projects__grid'>
        {projects.map((project) => (
          <>
            {project.images.map((image) => (
              <link
                rel='preload'
                href={`${process.env.PUBLIC_URL}/${image.image}`}
                as='image'
              />
            ))}
            <ProjectContainer key={uniqid()} project={project} />
          </>
        ))}
      </div>
    </section>
  )
}

export default Projects
