const header = {
  // all the properties are optional - can be left empty or deleted
  homepage: 'https://bentarr.com',
  title: 'BT.',
}

const about = {
  // all the properties are optional - can be left empty or deleted
  name: 'Ben Tarr',
  role: 'engineer with a passion for robotics',
  description:
    "I'm a student at Olin College of Engineering, Class of 2026. For the past two summers, I've been researching agricultural robotics at Carnegie Mellon. My interests include robotics, aerospace, UAVs, and anything involving additive manufacturing.",

  social: {
    linkedin: 'https://linkedin.com/in/ben-tarr',
    github: 'https://github.com/cmot17',
  },
}

const projects = [
  // projects can be added and removed
  // if there are no projects, Projects section won't show up
  {
    name: 'Formula SAE Battery Mounts',
    description:
      "I executed the design and fabrication of the battery pack mounting system for Olin's Formula SAE Electric vehicle, while gaining expertise in FEA and mechanical design.",
    stack: ['SolidWorks', 'FEA', 'Machining'],
    long_description:
      'Using SOLIDWORKS and foundational mechanical design principles, I designed the mounting brackets for the main battery pack of an electric racecar. Each individual bracket was designed and validated (through FEA and hand calculations) to support 10kN of force in any direction, while also allowing the quick removal and insertion of the battery pack. From those designs, I fabricated all 10 brackets using a mill and waterjet. Lastly, the mounts were integrated into the larger system in preparation for competition.',
    images: [
      {
        image: 'blocks1.jpeg',
        alt: 'The battery mounts being test fit on the full battery assembly',
      },
      { image: 'blocks2.jpeg', alt: 'The machined blocks lined up' },
      {
        image: 'blocks3.jpeg',
        alt: 'The blocks being fit into the chassis for welding',
      },
      {
        image: 'blocks4.jpeg',
        alt: 'All 10 blocks lined up again from a different angle',
      },
    ],
  },
  {
    name: 'VoteFalcon',
    description:
      'Startup company designed to increase the efficiency of political campaign’s canvassing. Uses AI and combinatorial optimization techniques to find optimal routes for people to walk.',
    stack: ['Python', 'Rust', 'ML', 'React Native'],
    long_description:
      'VoteFalcon leverages AI and combinatorial optimization to transform political campaigning. By predicting voter outcomes and analyzing sociopolitical indicators, we provide campaigns with optimal walking routes that maximize voter engagement. Our goal is to replace traditional canvassing and use data-driven strategies to focus effort where it matters most.',
    images: [
      {
        image: 'VoteFalcon One Pager.jpg',
        alt: `A brochure with an overview of VoteFalcon's features. Automate your canvassing.
    By cutting turf automatically and optimizing your walking routes, you can visit more voters in less time.
    
    Cutting turf made efficient.
    Studies show canvassing is still the most effective way to reach voters, but other tools have not caught up with advancements in modern optimization.
    
    Powered by AI.
    Our algorithm generates automated canvassing routes, mathematically optimized for efficiency and safety, in under a minute.
    
    Canvas with ease.
    Volunteers and staffers use our app—designed with real canvassers in mind—to reach voters and automatically sync form data with your campaign.
    `,
      },
    ],
  },
  {
    name: 'Agricultural Robot Platform',
    description:
      'Designed hardware and software to integrate a 6 DoF robot arm with a skid-steering chassis in a limited time frame, and then traveled to Iowa State University to test for 2 weeks in the field',
    stack: ['Mechanical Design', 'ROS', 'System Integration'],
    long_description:
      'Using Onshape, I designed an enclosure for electronics and a mount for the xArm6 that mounted on the Clearpath Robotics Husky chassis. After fabricating the parts using a CNC router, I assembled and integrated the electronics, battery system, and computer of the robot. During the two weeks in the field, the robot was used to collect valuable data and to test 3 different experimental end-effectors, while I provided on-site support for the usage of the robot and the ROS-based control software.',
    images: [
      {
        image: 'in_field1.png',
        alt: 'The robot in a field, with the gripper reaching for a pepper plant.',
      },
      {
        image: 'fully_assembled.png',
        alt: 'The fully assembled robot before heading to Iowa',
      },
      {
        image: 'battery_case.png',
        alt: 'A battery pack with a custom machined aluminum case that I designed',
      },
      {
        image: 'in_progress.png',
        alt: 'The robot with its main frame assembled',
      },
      {
        image: 'in_field2.png',
        alt: 'The robot in a field, with the gripper reaching for a pepper plant, from another angle',
      },
    ],
  },
]

const skills = [
  // skills can be added or removed
  // if there are no skills, Skills section won't show up
  'Python',
  'Java',
  'C++',
  'Rust',
  'MATLAB',
  'React',
  'ROS',
  'Circuit Design',
  'Onshape',
  'SOLIDWORKS',
  'Fusion 360',
  'CAM/CNC',
  '3D Printing',
  'Mechanical Design',
]

const contact = {
  // email is optional - if left empty Contact section won't show up
  email: 'btarr@olin.edu',
}

export { header, about, projects, skills, contact }
