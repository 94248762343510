import React, { useState } from 'react'
import uniqid from 'uniqid'
import GitHubIcon from '@mui/icons-material/GitHub'
import LaunchIcon from '@mui/icons-material/Launch'
import ProjectDetail from '../ProjectDetail/ProjectDetail'
import './ProjectContainer.css'

function ProjectContainer({ project }) {
  const [showDetails, setShowDetails] = useState(false)

  const handleKeyDown = (event) => {
    // Check if the key pressed is 'Enter' or 'Space'
    if (event.key === 'Enter' || event.key === ' ') {
      setShowDetails(true)
    }
    if (event.key === 'Escape') {
      setShowDetails(false)
    }
  }

  return (
    <div className='project_details_wrapper'>
      {showDetails && (
        <ProjectDetail
          project={project}
          onClose={() => setShowDetails(false)}
        />
      )}
      <div
        className='project'
        onClick={() => setShowDetails(true)}
        onKeyDown={handleKeyDown}
        role='button'
        tabIndex='0'
      >
        <h3>{project.name}</h3>

        <p className='project__description'>{project.description}</p>
        {project.stack && (
          <ul className='project__stack'>
            {project.stack.map((item) => (
              <li key={uniqid()} className='project__stack-item'>
                {item}
              </li>
            ))}
          </ul>
        )}

        {project.sourceCode && (
          <a
            href={project.sourceCode}
            aria-label='source code'
            className='link link--icon'
          >
            <GitHubIcon />
          </a>
        )}

        {project.livePreview && (
          <a
            href={project.livePreview}
            aria-label='live preview'
            className='link link--icon'
          >
            <LaunchIcon />
          </a>
        )}
      </div>
    </div>
  )
}

export default ProjectContainer
